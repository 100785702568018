$accent: map-get($theme-palette, accent);

.dropdown-menu {
    left: 0;
    min-width: 100%;
    border-radius: 0px;
}

.dropdown-item {
    &:hover {
        background-color: $accent;
        color: $white;
        text-decoration: none;
    }

    &:focus {
        background-color: $accent;
        color: $white;
        text-decoration: none;
    }

    &:active {
        background-color: $accent;
        color: $white;
        text-decoration: none;
    }
}

.navbar-primary {
    .dropdown-toggle {
        &:after {
            display: none;
        }
    }
}

.navbar-primary .nav-item:hover {
    background-color: transparent;
    color: $white;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(19, 18, 18, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(19, 18, 18, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(19, 18, 18, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(19, 18, 18, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(19, 18, 18, 0.9);
}

.navbar-light .navbar-toggler {
    border-color: rgba(19, 18, 18, 0.1);
    color: rgba(19, 18, 18, 0.5);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(19, 18, 18, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(19, 18, 18, 0.5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(19, 18, 18, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: white;
}

.navbar-dark .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text,
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: white;
}

.show>i.icon-toggle_plus-minus:before,
.dropdown:hover i.icon-toggle_plus-minus:before,
.accordion i.icon-toggle_plus-minus:before {
    content: "\f146";
}

.accordion .collapsed i.icon-toggle_plus-minus:before {
    content: "\f0fe";
}

i.icon-toggle_plus-minus:before {
    content: "";
}

.active i.icon-toggle_plus-minus:before,
.active>i.icon-toggle_plus-minus:before {
    content: "";
}

@media only screen and (min-width : 480px) {
    .navbar-expand-sm {
        .dropdown {
            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (min-width : 768px) {
    .navbar-primary.navbar-expand-md {
        .nav-item {
            border-top: 0.25rem $acc-blue solid;
            border-bottom: 0.25rem $acc-blue solid;

            &:hover {
                border-top-color: $accent;
                border-bottom-color: $white;
                background-color: $white;

                .nav-link {
                    color: $acc-blue;
                }
            }
        }

        .nav-item.active {
            border-top-color: $accent;
            border-bottom-color: $white;
            background-color: $white;

            .nav-link {
                color: $acc-blue;
            }
        }

        .nav-item.show {
            border-top-color: $accent;
            border-bottom-color: $white;
            background-color: $white;

            .nav-link {
                color: $acc-blue;
            }
        }
    }

    .navbar-expand-md {
        .dropdown {
            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (min-width : 1024px) {
    .navbar-primary.navbar-expand-lg {
        .nav-item {
            &:hover {
                border-top-color: $accent;
                border-bottom-color: $white;
                background-color: $white;

                .nav-link {
                    color: $acc-blue;
                }
            }
        }

        .nav-item.active {
            border-top-color: $accent;
            border-bottom-color: $white;
            background-color: $white;

            .nav-link {
                color: $acc-blue;
            }
        }

        .nav-item.show {
            border-top-color: $accent;
            border-bottom-color: $white;
            background-color: $white;

            .nav-link {
                color: $acc-blue;
            }
        }
    }

    .navbar-expand-lg {
        .dropdown {
            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }
    }
}