/*
Backend Content Decorators

sfEditor-show					- a class for showing items that might be only useful for a backend CMS User.
visible-anonymous-block 		- decoration for containers that hold content for users not logged in.
visible-authenticated-block		- decoration for containers that hold content for users are authenticated.


Markup:
<div class="{{modifier_class}} ">
	<h3 class="m_4">{{modifier_class}}</h3>
</div>


MarkupWrapperClasses: sfPageEditor

HideCodeBlock: display_none

Weight: 10000

Styleguide Sitefinity.BackEndAuthenticated
*/

%editor-visible {
	display: block !important;
	opacity: 0.75;
	border: 2px dotted transparent;
	margin: 5px;
}
%editor-visible-message {
	display: block;
	position: relative;
	font-size: 12px;
	text-align: center;
}
.sfEditor-show{display:none;}
.sfPageEditor .sfEditor-show{ display:block !important; opacity:.5;}

.sfPageEditor {
	.visible-anonymous-block,
	.visible-authenticated-block {
		img {
			display: none;
		}
	}
	.visible-authenticated-block {
		@extend %editor-visible;
		border-color: get-theme-color(accent,2,CSSVAR);
		background-color:get-theme-color(accent,5,CSSVAR);
		&:after,
		&:before {
			content: "AUTHENTICATED CONTENT -  Permision/Role/Authenticated";
			color: get-theme-color(accent,-1,CSSVAR);
			@extend %editor-visible-message;
		}
	}
	.visible-anonymous-block {
		@extend %editor-visible;
		border-color: get-theme-color(highlight,2,CSSVAR);
		background-color:get-theme-color(highlight,5,CSSVAR);
		&:after,
		&:before {
			content: "ANONYMOUS CONTENT - Permision/Role/Anonymous";
			color: get-theme-color(highlight,-1,CSSVAR);
			@extend %editor-visible-message;
		}
	}
}
